import React from 'react';
import { observer } from 'mobx-react';
import __ from 'core/lib/localization';
// import { MediumHeading } from 'naan/primitives/text';
// import { VSpacer } from 'naan/primitives/spacer';
import {
  Table,
  TableRow,
  TableLabelCell,
  TableValueCell,
} from 'components/account/account-form/table';
import { LargeGap } from '@naan/primitives/spacer/gaps';
import { formatDate } from 'utils/format-date';
import { LocaleCode } from '@utils/util-types';
import { AppFactory } from '@app/app-factory';

export const GroupAccess = observer(({ l2 }: { l2: LocaleCode }) => {
  const { userManager } = AppFactory.root;
  const membershipData = userManager.membershipDataL2(l2);
  const { membershipTypeDisplay, fullAccessUntil, licensedClassroomLabel } =
    membershipData;

  return (
    <>
      {/* <MediumHeading>{__('My membership', 'myMembership')}</MediumHeading>
        <VSpacer size="4" /> */}
      <Table>
        <tbody>
          <TableRow>
            <TableLabelCell>
              {__('Membership type', 'membershipType')}
            </TableLabelCell>
            <TableValueCell>{membershipTypeDisplay}</TableValueCell>
          </TableRow>
          <TableRow>
            <TableLabelCell>
              {__('Classroom name', 'classroomName')}
            </TableLabelCell>
            <TableValueCell>{licensedClassroomLabel}</TableValueCell>
          </TableRow>
          <TableRow>
            <TableLabelCell>{__('Expires', 'expires')}</TableLabelCell>

            <TableValueCell>{formatDate(fullAccessUntil)}</TableValueCell>
          </TableRow>
        </tbody>
      </Table>
      <LargeGap />
    </>
  );
});
