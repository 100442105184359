import { styled } from '@naan/stitches.config';
import { learnHomePath, profileHomePath } from 'components/nav/path-helpers';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { useReform } from './use-reform';
import { AppFactory } from '@app/app-factory';
import { formatDate } from '@utils/format-date';

import __ from '@core/lib/localization';
import { Button } from '@naan/primitives/button';
import { AvatarIcon } from '@naan/icons/avatar-icon';

const Wrapper = styled('div', {
  paddingTop: 48,
  '& .copy': {
    marginBottom: -80,
    padding: 16,
    '&  h2': {
      textStyle: 'large-heading',
      marginBottom: 8,
    },
  },

  '& #my-reform': {
    margin: '0 auto',

    width: 'min(100%, 496px)',
    // padding: '48px 32px',
  },

  '& .account-button-container': {
    display: 'flex',
    justifyContent: 'center',
    paddingBottom: 40,
  },
});

// extend window with Reform
declare global {
  // eslint-disable-next-line no-unused-vars
  interface Window {
    Reform: any;
  }
}

// function sleepPromise(ms: number) {
//   return new Promise(resolve => setTimeout(resolve, ms));
// }

export const ExitSurveyScreen = () => {
  // const { fullAccessUntil } = AppFactory.root.userManager;
  const { root } = AppFactory;
  const fullAccessUntil = root.userManager.fullAccessUntilL2(root.l2);

  const navigate = useNavigate();

  const onFormSubmitted = React.useCallback(
    async (data: any) => {
      // eslint-disable-next-line no-console
      console.log('Form submitted', data); // @armando is there a way to get our own logger to display objects nicely? (i'm wondering if that's something you had working once upon a time and i butchered along the way)
      // await sleepPromise(1000);
      navigate(learnHomePath());
    },
    [navigate]
  );

  useReform({
    formUrl: 'https://forms.reform.app/QVIesK/exit-survey/z9pdpw',
    onFormSubmitted,
    targetId: '#my-reform',
    emailFieldName: 'e293485e-8099-4b87-975f-13e3b5ecce33',
    userIdFieldName: '2017c041-f21e-4b38-91cc-a906322c2c4c',
  });

  return (
    <Wrapper>
      <div className="copy">
        <h2>{__('Sorry to see you go', 'sorryToSeeYouGo')}</h2>
        <p className="intro">
          {__(
            "You'll still have full access until %{date}. If you have a moment, please let us know why you cancelled.",
            'youllStillHaveFullAccess',
            { date: formatDate(fullAccessUntil) }
          )}
        </p>
      </div>
      <div id="my-reform"></div>
      <div className="account-button-container">
        <Button
          leftIcon={<AvatarIcon />}
          presentation="grayLight"
          label={__('My account', 'myAccount')}
          onClick={() => {
            navigate(profileHomePath());
          }}
        />
      </div>
    </Wrapper>
  );
};
