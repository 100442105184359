import React from 'react';
import { observer } from 'mobx-react';
import {
  AnonymousPlanPricingCard,
  PlanPricingCard,
} from '../plan-pricing-card';
import { Plan } from '@cas-shared/plan';
import { AppFactory } from '@app/app-factory';
import { PricingCardsWrapper } from './pricing-cards-wrapper';
import { resolvePlans } from '@core/models/user-manager/user-manager-helpers';
import { LocaleCode } from '@utils/util-types';

export const PricingCards = observer(({ l2 }: { l2: LocaleCode }) => {
  const { root } = AppFactory;
  const { discountScheme } = root.userManager;
  const plans: Plan[] = resolvePlans(root, l2);
  return (
    <PricingCardsWrapper>
      {plans.map(plan => (
        <PlanPricingCard
          key={plan.slug}
          plan={plan}
          discountScheme={discountScheme}
        />
      ))}
    </PricingCardsWrapper>
  );
});

//
// to test this anonymous affiliate pricing, use an url in the form of:
//   http://localhost:3100/?utm_medium=alp&utm_source=fojw
//

export const AnonPricingCards = observer(({ l2 }: { l2: LocaleCode }) => {
  const plans: Plan[] = resolvePlans(AppFactory.root, l2);
  return (
    <PricingCardsWrapper>
      {plans.map(plan => (
        <AnonymousPlanPricingCard plan={plan} />
      ))}
    </PricingCardsWrapper>
  );
});
