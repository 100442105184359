import deepmerge from 'deepmerge';
import { config as baseConfig } from './base-devtest';
import { CatalogsMap } from '@utils/util-types';

//
// brasil.jiveworld.app
//
// for carolina's demos of the PT L1 / EN L2 config
//

export const config = deepmerge(baseConfig, {
  // showUnhandledErrors: true,

  // apiEnv: 'staging',
  apiEnv: 'beta',
  caliServerEnv: 'beta',

  piMenuEnabled: false,
  // inviteGateEnabled: true,

  // forceFullAccess: true, // bypass purchase flow UI until multi-L2 support flushed now
  // forceNoPurchase: true, // beware this flag also disables the classroom flows, so not useful for brasil demo

  // cookieDomain: 'dev.jiveworld.app',

  website: {
    urls: {
      //   marketingSite: 'https://www.test.jiveworld.app',
      jwPressServer: 'https://resources-test.jiveworld.app/',
    },
    // },
    // not relevant - no marketing site peer to dev/test
    // cookieDomain: 'dev.jiveworld.app',
  },

  defaultL1: 'pt',
  // forcedL2: 'en',
  catalogs: {
    es: {
      defaultL1: 'en',
      en: 'lupa',
    },
    en: {
      defaultL1: 'pt',
      pt: 'english-pt',
    },
  } as CatalogsMap,
});
