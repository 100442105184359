import * as React from 'react';
import * as Dialog from '@naan/primitives/modals/dialog';
import { observer } from 'mobx-react';
// <<<<<<< HEAD
// import { SettingsModalContainer } from './settings-modal-container';
// import { styled } from '@naan/stitches.config';
// import { Button } from '@naan/primitives/button';
// // import { Select } from '@naan/primitives/input/select';
// import { AppFactory } from '@app/app-factory';
// import { notifySuccess } from '@app/notification-service';
// import { SwitchButton } from 'components/ds/switch-button';
// import { HSpacer } from '@naan/primitives/spacer';
// import { useSwitch2 } from '@naan/hooks/use-switch-2';
// import { HStack } from '@naan/primitives/stack';
// import { Text } from '@naan/primitives/text';
// import __, { localizationDisabled } from '@core/lib/localization';
// // import { LocaleCode } from '@utils/util-types';
// =======
import { keyframes, styled } from '@naan/stitches.config';
import { AppFactory } from '@app/app-factory';
import { GeneralSettingsTab } from './general-settings-tab';
import { useRadio } from '@common/hooks/use-radio';
import { PlayerSettingsTab } from './player-settings-tab';
import { DialogHtmlContainer } from 'components/ui/dialog-html-container';

import __ from '@core/lib/localization';

const contentShow = keyframes({
  '0%': { opacity: 0, transform: 'translate(-50%, -48%) scale(.75)' },
  '100%': { opacity: 1, transform: 'translate(-50%, -50%) scale(1)' },
});

const Heading = styled('div', {
  display: 'flex',
  padding: '16px 16px 8px',
  textStyle: 'small-heading',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'relative',

  // '&::after': {
  //   content: '""',
  //   display: 'block',
  //   height: '1px',
  //   backgroundColor: '$gray-100',
  //   position: 'absolute',
  //   bottom: 0,
  //   left: 16,
  //   right: 16,
  // },
});

const Content = styled(Dialog.HtmlContent, {
  $$maxWidth: '600px',
  $$maxHeight: '600px',
  backgroundColor: '$white',
  borderRadius: 12,
  boxShadow: `0px 1px 4px $colors$black-alpha-20`,
  position: 'fixed',
  top: 'calc(50% + (var(--sat) / 2) - (var(--sab) / 2))',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 'min(calc(100% - 32px), $$maxWidth)',
  height: 'calc(100% - 32px - var(--sat) - var(--sab))',
  maxHeight: 'none',
  display: 'flex',
  flexDirection: 'column',
  '@medium': {
    maxHeight: 'min(calc(100% - 32px - var(--sat) - var(--sab)), $$maxHeight)',
  },
  '@media (prefers-reduced-motion: no-preference)': {
    animation: `${contentShow} 0.5s cubic-bezier(0.16, 1, 0.3, 1)`,
    willChange: 'transform',
  },
  '&:focus': { outline: 'none' },
});

const TabsNav = styled('div', {
  display: 'flex',
  borderBottom: '1px solid $colors$gray-100',
  margin: '0 16px',
  '& > button': {
    $$color: '$colors$textSecondary',
    $$indicatorColor: '$colors$transparent',
    all: 'unset',
    marginBottom: -1,
    flex: 1,
    textStyle: 'body-bold',
    padding: '16px 0 12px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: '$$color',
    borderBottom: '4px solid $$indicatorColor',
    '&:hover': {
      $$color: '$colors$teal-500',
      // $$indicatorColor: '$colors$teal-500',
    },
    '&.active': {
      $$color: '$colors$teal-500',
      $$indicatorColor: '$colors$teal-500',
    },
  },
});

// <<<<<<< HEAD
// const ResetTipGuard = styled('div', {
//   display: 'flex',
//   alignItems: 'center',
//   gap: '8px',
//   flexWrap: 'wrap',
// });

// const ImmersiveViewSwitch = observer(() => {
//   const { userSettings } = AppFactory.root.userManager.userData;
//   const toggle = () => userSettings.toggleImmersiveView();
//   return (
//     <SwitchButton
//       onChange={toggle}
//       active={userSettings.immersiveViewEnabled}
//     />
//   );
// });

// const VocabExportSwitch = observer(() => {
//   const { userSettings } = AppFactory.root.userManager.userData;
//   const toggle = () => userSettings.toggleVocabExport();
//   return (
//     <SwitchButton
//       onChange={toggle}
//       active={userSettings.showVocabListExportOption}
//     />
//   );
// });

// const ResetTipsButton = observer(() => {
//   const { userSettings } = AppFactory.root.userManager.userData;
//   const dialogSwitch = useSwitch2(false);

//   return (
//     <>
//       {dialogSwitch.value ? (
//         <ResetTipGuard>
//           <Text textStyle="small-text-bold" color="textPrimary">
//             {__('Are you sure?', 'areYouSure')}
//           </Text>
//           <HStack>
//             <Button
//               label={__('Yes, reset', 'yesReset')}
//               presentation="redLight"
//               size={'small'}
//               onClick={() => {
//                 userSettings.resetAllTips();
//                 notifySuccess(
//                   __('Quickstart guide reset', 'quickstartGuideReset')
//                 );
//                 dialogSwitch.off();
//               }}
//             />
//             <HSpacer size="2" />
//             <Button
//               label={__('Cancel', 'cancel')}
//               presentation="grayLight"
//               size={'small'}
//               onClick={dialogSwitch.off}
//             />
//           </HStack>
//         </ResetTipGuard>
//       ) : (
//         <Button
//           label={__('Reset story and guide…', 'resetStoryAndGuide')}
//           presentation={dialogSwitch.value ? 'redLight' : 'grayLight'}
//           size={'small'}
//           onClick={dialogSwitch.on}
//         />
//       )}
//     </>
//   );
// });

// export const SettingsModal = observer(
//   ({ onDismiss }: { onDismiss: () => void }) => {
//     const { authenticated } = AppFactory.root.userManager;
//     const l2 = AppFactory.root.l2Localized;

//     return (
//       <SettingsModalContainer onDismiss={onDismiss}>
//         <Wrapper>
//           {localizationDisabled ? null : (
//             <div className="row stack-on-small">
//               <div className="left">
//                 {/* <div className="title">
//                   {__('Buttons and labels', 'buttonsAndLabels')}
//                 </div>
//                 <div className="hint">
//                   {__(
//                     "Select a language for Jiveworld's user interface",
//                     'selectALanguageForJiveworldsUserInterface'
//                   )}
//                 </div> */}
//                 <div className="title">
//                   {__('Immersive view', 'immersiveView')}
//                 </div>
//                 <div className="hint">
//                   {__(
//                     'Show buttons, labels etc in %{l2}',
//                     'showButtonsLabelsEtcInL2',
//                     { l2 }
//                   )}
//                 </div>
//               </div>
//               <div className="right">
//                 {/* <LocaleToggle /> */}
//                 <ImmersiveViewSwitch />
//               </div>
//             </div>
//           )}

//           <div className="row">
//             <div className="left">
//               <div className="title">
//                 {__('Quickstart guide', 'welcomeTips')}
//               </div>
//               <div className="hint">
//                 {__(
//                   'Learn the Jiveworld Method step by step with a short story.',
//                   'learnTheJiveworldMethodStepByStepWithAShortStory'
//                 )}
//               </div>
//             </div>
//             {/* <div className="right">
//               <SwitchButton
//                 active={userSettings.welcomeTipsEnabled}
//                 onChange={() => userSettings.toggleWelcomeTips()}
//               />
//             </div> */}
//             <div className="bottom">
//               <ResetTipsButton />
//             </div>
// =======
const tabs = ['General', 'Player'] as const;
type SettingsTab = typeof tabs[number];

export const SettingsModal = observer(
  ({
    onDismiss,
    initialTab = 'General',
  }: {
    onDismiss: () => void;
    initialTab?: SettingsTab;
  }) => {
    const tabState = useRadio<SettingsTab>(initialTab);

    return (
      <DialogHtmlContainer
        onDismiss={onDismiss}
        contentComponent={Content as any}
      >
        <Heading>
          {__('Settings', 'settings')}
          <span className="close-btn">
            <Dialog.HtmlCloseButton onDismiss={onDismiss} />
          </span>
        </Heading>
        <TabsNav>
          <button
            className={tabState.currentTab === 'General' ? 'active' : ''}
            onClick={() => tabState.setCurrentTab('General')}
          >
            {__('General', 'general')}
          </button>
          <button
            className={tabState.currentTab === 'Player' ? 'active' : ''}
            onClick={() => tabState.setCurrentTab('Player')}
          >
            {__('Player', 'player')}
          </button>
        </TabsNav>
        <Dialog.Body>
          <div className="tabs-content">
            {tabState.currentTab === 'General' ? <GeneralSettingsTab /> : null}
            {tabState.currentTab === 'Player' ? <PlayerSettingsTab /> : null}
          </div>
        </Dialog.Body>
      </DialogHtmlContainer>
    );
  }
);

export const presentSettingsModal = (initialTab: SettingsTab = 'General') => {
  AppFactory.dialogPresenter.present(onDismiss => (
    <SettingsModal onDismiss={onDismiss} initialTab={initialTab} />
  ));
};
