import * as React from 'react';
import * as Dialog from '@naan/primitives/modals/dialog';

import { observer } from 'mobx-react';
import { styled } from '@naan/stitches.config';
import { Button } from '@naan/primitives/button';
// import { Select } from '@naan/primitives/input/select';
import { AppFactory } from '@app/app-factory';
import { notifySuccess } from '@app/notification-service';
import { SwitchButton } from 'components/ds/switch-button';
// import { HSpacer } from '@naan/primitives/spacer';
import { useSwitch2 } from '@naan/hooks/use-switch-2';
// import { HStack } from '@naan/primitives/stack';
// import { Text } from '@naan/primitives/text';
import __, { localizationDisabled } from '@core/lib/localization';
import { ExternalLink } from '@naan/primitives/text/external-link';

const Wrapper = styled('div', {
  '& > .row': {
    padding: '16px 0',
    display: 'grid',
    gridTemplateColumns: '1fr auto',
    gap: 16,
    '&:last-child': {
      paddingBottom: '8px',
    },
    '&.stack-on-small': {
      gridTemplateColumns: '1fr',
      gridTemplateRows: 'auto auto',
      '@medium': {
        gridTemplateColumns: '1fr auto',
        gridTemplateRows: 'auto',
      },
    },
    '& + &': {
      borderTop: '1px solid $colors$gray-100',
    },
    '& > .left': {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      gap: 4,
      '& > .title': {
        textStyle: 'body',
        color: '$textPrimary',
      },
      '& > .hint': {
        textStyle: 'small-text',
        color: '$textSecondary',
      },
      '& > .extra': {
        '& > a': {
          color: '$textSecondary',
          textStyle: 'small-text',
        },
      },
    },
    '& > .bottom': {
      gridColumn: 'span 2',
    },
  },
});

// const ResetTipGuard = styled('div', {
//   display: 'flex',
//   alignItems: 'center',
//   gap: '8px',
//   flexWrap: 'wrap',
// });

// const LocaleToggle = observer(() => {
//   const { localState } = AppFactory.root;
//   const locale = getLocale();
//   // const locales = ['en', 'es', 'de', 'pt'];
//   // const locales = getAvailableTranslations();
//   return (
//     <Select
//       value={locale}
//       onChange={event => {
//         // setLocale(event.target.value);
//         localState.storeLocale(event.target.value).catch(bugsnagNotify);
//       }}
//     >
//       {/* todo: confirm how these labels should be presented, likely unlocalized in own language */}
//       <option value={'en'}>English</option>
//       <option value={'es'}>Español</option>
//       <option value={'pt'}>Português</option>
//       {/* <option value={'de'}>{__('German', 'german')}</option> */}
//     </Select>
//   );
// });

const VocabExportSwitch = observer(() => {
  const { userSettings } = AppFactory.root.userManager.userData;
  const toggle = () => userSettings.toggleVocabExport();
  return (
    <SwitchButton
      onChange={toggle}
      active={userSettings.showVocabListExportOption}
    />
  );
});

const AutoDownloadSwitch = observer(() => {
  const { userSettings } = AppFactory.root.userManager.userData;
  const toggle = () => userSettings.toggleAutoDownload();
  return (
    <SwitchButton onChange={toggle} active={userSettings.autoDownloadEnabled} />
  );
});

function useAdhocDialog() {
  const dialogPresenter = AppFactory.dialogPresenter;
  const dialogSwitch = useSwitch2(false);
  const open = () => {
    if (!dialogPresenter.instance.blocked) {
      dialogSwitch.on();
      if (dialogPresenter.instance.element) {
        dialogPresenter.instance.block();
      }
    }
  };
  const close = () => {
    dialogSwitch.off();
    // without the timeout, this dismiss and a parent dismiss will execute at the same time
    window.setTimeout(() => {
      dialogPresenter.instance.unblock();
    }, 100);
  };
  return { showing: dialogSwitch.value, open, close };
}

const ResetTipsButton = observer(() => {
  const { userSettings } = AppFactory.root.userManager.userData;
  const dialog = useAdhocDialog();

  return (
    <>
      {dialog.showing ? (
        <Dialog.Container open onDismiss={() => dialog.close()}>
          <Dialog.Body>{__('Are you sure?', 'areYouSure')}</Dialog.Body>
          <Dialog.ButtonsContainer>
            <Dialog.Button
              label={__('Cancel', 'cancel')}
              presentation="grayLight"
              onClick={dialog.close}
            />
            <Dialog.Button
              label={__('Yes, reset', 'yesReset')}
              presentation="redLight"
              onClick={() => {
                userSettings.resetAllTips();
                dialog.close();
                notifySuccess(
                  __('Quickstart guide reset', 'quickstartGuideReset')
                );
              }}
            />
          </Dialog.ButtonsContainer>
        </Dialog.Container>
      ) : null}
      <Button
        label={__('Reset story and guide…', 'resetStoryAndGuide')}
        presentation={'grayLight'}
        size={'small'}
        onClick={dialog.open}
      />
    </>
  );
});

const ImmersiveViewSwitch = observer(() => {
  const { userSettings } = AppFactory.root.userManager.userData;
  const toggle = () => userSettings.toggleImmersiveView();
  return (
    <SwitchButton
      onChange={toggle}
      active={userSettings.immersiveViewEnabled}
    />
  );
});

export const GeneralSettingsTab = observer(() => {
  const { root } = AppFactory;
  const { authenticated } = root.userManager;
  const l2 = root.l2Localized;

  return (
    <>
      <Wrapper>
        {/* localizationDisabled ? null : (
          <div className="row stack-on-small">
            <div className="left">
              <div className="title">
                {__('Buttons and labels', 'buttonsAndLabels')}
              </div>
              <div className="hint">
                {__(
                  "Select a language for Jiveworld's user interface",
                  'selectALanguageForJiveworldsUserInterface'
                )}
              </div>
            </div>
            <div className="right">
              <LocaleToggle />
            </div>
          </div>
        ) */}

        {localizationDisabled ? null : (
          <div className="row stack-on-small">
            <div className="left">
              <div className="title">
                {__('Immersive view', 'immersiveView')}
              </div>
              <div className="hint">
                {__(
                  'Show buttons, labels etc in %{l2}',
                  'showButtonsLabelsEtcInL2',
                  { l2 }
                )}
              </div>
            </div>
            <div className="right">
              <ImmersiveViewSwitch />
            </div>
          </div>
        )}

        <div className="row stack-on-small">
          <div className="left">
            <div className="title">
              {__('Download stories', 'downloadStories')}
            </div>
            <div className="hint">
              {__(
                `Stores in-progress and "Study later" stories on your device so they're available offline`,
                'downloadStoriesSettingExplanation'
              )}
            </div>
          </div>
          <div className="right">
            <AutoDownloadSwitch />
          </div>
        </div>

        <div className="row">
          <div className="left">
            <div className="title">{__('Quickstart guide', 'welcomeTips')}</div>
            <div className="hint">
              {__(
                'Learn the Jiveworld Method step by step with a short story.',
                'learnTheJiveworldMethodStepByStepWithAShortStory'
              )}
            </div>
          </div>
          {/* <div className="right">
              <SwitchButton
                active={userSettings.welcomeTipsEnabled}
                onChange={() => userSettings.toggleWelcomeTips()}
              />
            </div> */}
          <div className="bottom">
            <ResetTipsButton />
          </div>
        </div>

        {authenticated ? (
          <div className="row">
            <div className="left">
              <div className="title">
                {__('Vocabulary export', 'vocabularyExport')}&nbsp;
                {__('(Experimental)', 'parenthesisExperimental')}
              </div>
              <div className="hint">
                {__(
                  'Exposes an option on story vocabulary list that emails a data file for importing into a flashcard program',
                  'exposesAnOptionOnStoryVocabularyList'
                )}
              </div>
              <div className="extra">
                <ExternalLink href={'http://go.jw.app/about-vocab-export'}>
                  {__('Learn more', 'learnMore')}
                </ExternalLink>
              </div>
            </div>
            <div className="right">
              <VocabExportSwitch />
            </div>
          </div>
        ) : null}
      </Wrapper>
    </>
  );
});
