import * as React from 'react';
import { ArrowLeftExtraSmallIcon } from '@naan/icons/arrow-left-icon';
import { ArrowRightExtraSmallIcon } from '@naan/icons/arrow-right-icon';
import { ArrowUpExtraSmallIcon } from '@naan/icons/arrow-up-icon';
import { ArrowDownExtraSmallIcon } from '@naan/icons/arrow-down-icon';
import { PlusExtraSmallIcon } from '@naan/icons/plus-icon';
import { MinusExtraSmallIcon } from '@naan/icons/minus-icon';
import { ShortcutSection } from './ui/shortcut-section';
import { ShortcutItem } from './ui/shortcut-item';

import __ from '@core/lib/localization';

export const SoundbiteShortcuts = () => {
  return (
    <>
      <ShortcutSection title={__('Playback', 'playback')}>
        <ShortcutItem
          title={__('Play/pause', 'playSlashPause')}
          shortcut="Space"
        />
        <ShortcutItem
          title={__('Rewind/fast-forward', 'rewindSlashFastForward')}
          shortcut={[<ArrowLeftExtraSmallIcon />, <ArrowRightExtraSmallIcon />]}
        />

        <ShortcutItem
          title={__('Previous/next sentence', 'previousSlashNextSentence')}
          shortcut={[<ArrowUpExtraSmallIcon />, <ArrowDownExtraSmallIcon />]}
        />

        <ShortcutItem
          title={__('Adjust speed', 'adjustSpeed')}
          shortcut={[<PlusExtraSmallIcon />, <MinusExtraSmallIcon />]}
        />
      </ShortcutSection>

      <ShortcutSection title={__('View', 'view')}>
        <ShortcutItem
          title={__('Reveal current sentence', 'revealCurrentSentence')}
          shortcut="Delete"
        />
        <ShortcutItem
          title={__(
            'Show/hide translation (after Reveal)',
            'showSlashHideTranslationAfterReveal'
          )}
          shortcut="Shift+Tab"
        />
        <ShortcutItem
          title={__('Show/hide vocabulary', 'showSlashHideVocabulary')}
          shortcut="V"
        />
      </ShortcutSection>

      <ShortcutSection title={__('When paused', 'whenPaused')}>
        <ShortcutItem title={__('Replay', 'replay')} shortcut="Enter" />
        <ShortcutItem
          title={__('Slow replay', 'slowReplay')}
          shortcut="Shift + Enter"
        />
        <ShortcutItem
          title={__(
            'Show/hide sentence translation (after Reveal)',
            'showSlashHideSentenceTranslationAfterReveal'
          )}
          shortcut="Tab"
        />

        <ShortcutItem
          title={__(
            'Show/hide sentence vocabulary',
            'showSlashHideSentenceVocabulary'
          )}
          shortcut="V"
        />
        <ShortcutItem
          title={__(
            'Show/hide saved vocabulary',
            'showSlashHideSavedVocabulary'
          )}
          shortcut="Shift + V"
        />
        <ShortcutItem
          title={__('Copy sentence to clipboard', 'copySentenceToClipboard')}
          shortcut="C"
        />
      </ShortcutSection>

      <ShortcutSection title={__('Help', 'help')}>
        <ShortcutItem
          title={__('Show/hide Soundbites help', 'showSlashHideSoundbitesHelp')}
          shortcut="?"
        />
        <ShortcutItem
          title={__('Show/hide this panel', 'showSlashHideThisPanel')}
          shortcut="K"
        />
      </ShortcutSection>
    </>
  );
};
