import * as React from 'react';
// import { SmTwitterIcon } from 'naan/icons/sm-twitter-icon';
// import { SmInstagramIcon } from 'naan/icons/sm-instagram-icon';
import { styled } from 'naan/stitches.config';
//@ts-expect-error
import { version } from '@jw-spa-version';
import { ExternalLink } from '@naan/primitives/text/external-link';
import {
  // contactUs,
  eulaUrl,
  privacyUrl,
  tosUrl,
} from 'components/nav/path-helpers';
import { buildInfoDisplay } from '@core/lib/app-util';
import { JiveworldLogo } from 'components/branding/jiveworld-logo';

import __, { addNbsps } from 'core/lib/localization';

const Wrapper = styled('footer', {
  // backgroundColor: '$colors$gray-800',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  gap: 8,
  paddingBottom: 'calc(var(--sab) + 40px)',

  '@medium': {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    borderTop: '1px solid $colors$gray-100',
    padding: '24px 0',
  },

  '& > .logos': {
    color: '$colors$gray-300',
  },

  '& > .text': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',

    '@medium': {
      alignItems: 'flex-end',
    },

    '& > .links': {
      // background: 'yellow',
      display: 'flex',
      gap: 20,

      '& a': {
        textDecoration: 'none',
        textStyle: 'small-text',
        color: '$colors$gray-400',
        '& + &:before': {
          content: '|',
          color: '$colors$gray-100',
          position: 'relative',
          left: -9,
        },
      },
    },

    '& > .footer-meta': {
      textStyle: 'tiny-text',
      color: '$colors$gray-400',
    },
  },
});

export const GlobalFooter = () => (
  <Wrapper>
    <div className="logos">
      <JiveworldLogo />
    </div>
    <div className="text">
      <div className="links">
        {/* beware, very similar but slightly different from auth-small-print version of these */}
        <ExternalLink href={tosUrl()}>
          {addNbsps(__('Terms of service', 'termsOfService'))}
        </ExternalLink>
        <ExternalLink href={privacyUrl()}>
          {__('Privacy', 'privacy')}
        </ExternalLink>
        <ExternalLink href={eulaUrl()}>{__('EULA', 'eula')}</ExternalLink>
      </div>
      <div className="footer-meta">
        {/* <ExternalLink href={contactUs()}>
        {__('Contact & support', 'contactSupport')}
      </ExternalLink> */}
        <span title={version}>{buildInfoDisplay()}</span>
      </div>
    </div>
  </Wrapper>
);
