import React from 'react';
import { observer } from 'mobx-react';
// import { MediumHeading } from 'naan/primitives/text';
import {
  Table,
  TableRow,
  TableLabelCell,
  TableValueCell,
} from 'components/account/account-form/table';
import { LargeGap } from '@naan/primitives/spacer/gaps';
import { VSpacer } from 'naan/primitives/spacer';
import __ from 'core/lib/localization';
import { formatDate } from 'utils/format-date';
import { AppFactory } from '@app/app-factory';
import { useSwitch } from '@naan/hooks/use-switch';
import { VStack } from '@naan/primitives/stack';
import { BlueLink } from 'components/ui/buttons/link-button';
import { prevent } from '@common/prevent';
import { PricingCards } from '../pricing-cards';
import { PlainMarkdown } from '@naan/primitives/text';
import { daysCount } from '@core/lib/localization-helpers';
import { LocaleCode } from '@utils/util-types';

export const FullNoRenew = observer(({ l2 }: { l2: LocaleCode }) => {
  // we no longer show pricing cards while access is still active
  const [changing, changingSwitch] = useSwitch(false);

  const { userManager } = AppFactory.root;
  const membershipData = userManager.membershipDataL2(l2);
  const { membershipTypeDisplay, fullAccessUntil, showAccountPageCheckout } =
    membershipData;

  if (userManager.purchaseFlowDisabled) {
    return null;
  }

  return (
    <>
      {/* <MediumHeading>{__('My membership', 'myMembership')}</MediumHeading>
        <VSpacer size="4" /> */}
      <Table>
        <tbody>
          <TableRow>
            <TableLabelCell>
              {__('Membership type', 'membershipType')}
            </TableLabelCell>
            <TableValueCell> {membershipTypeDisplay}</TableValueCell>
          </TableRow>

          {/* <TableRow>
              <TableLabelCell>
                {__('Recurring billing', 'recurringBilling')}
              </TableLabelCell>
              <TableValueCell>{recurringBillingDisplay}</TableValueCell>
            </TableRow> */}

          <TableRow>
            <TableLabelCell>{__('Expires', 'expires')}</TableLabelCell>
            <TableValueCell>
              {formatDate(fullAccessUntil)}
              {showAccountPageCheckout ? (
                <BlueLink onClick={prevent(changingSwitch.toggle) as any}>
                  {changing ? __('Cancel', 'cancel') : __('Extend', 'extend')}
                </BlueLink>
              ) : null}
            </TableValueCell>
          </TableRow>
          {changing ? (
            <TableRow noBorder={true}>
              <td colSpan={2}>
                <VStack>
                  <h3>{__('Extend membership', 'extendMembership')}</h3>
                  <PlainMarkdown
                    source={__(
                      'By extending your membership, you will be starting a new subscription. You will **not** lose your remaining access ({{duration}}) -- these will appear as "trial days" on the next screen, before your your first charge.',
                      'extendMembershipInfo',
                      {
                        duration: daysCount(
                          membershipData.remainingFullAccessDays
                        ),
                      }
                    )}
                  />
                  <VSpacer size={10} />
                  <PricingCards l2={l2} />
                  <VSpacer size={10} />
                </VStack>
              </td>
            </TableRow>
          ) : null}
        </tbody>
      </Table>
      <LargeGap />
    </>
  );
});
