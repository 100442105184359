import * as React from 'react';
import { PlainMarkdown } from '@naan/primitives/text';
import { styled } from '@naan/stitches.config';

const MarkdownWrapper = styled('div', {
  '& p': {
    textStyle: 'body',
    marginBottom: '16px',
  },
  '& strong': {
    textStyle: 'body-bold',
  },
  '& strong em': {
    fontWeight: '500',
    fontStyle: 'italic',
  },
  '& blockquote': {
    marginBottom: 16,
    margin: '24px 0',
    paddingLeft: 32,
    position: 'relative',
    '&:before': {
      content: '',
      display: 'block',
      position: 'absolute',
      top: 4,
      bottom: 4,
      left: 16,
      width: 4,
      background: '$orange-300',
    },
    // :not(:has()) here is to target quotes without h1s
    '& h1, &:not(:has(h1)) p': {
      textStyle: 'serif-small',
      marginBottom: '0',
      color: '$orange-600',
      '& strong': {
        textStyle: 'serif-small-bold',
      },
    },
    '& p': {
      textStyle: 'body',
      marginBottom: '0',
      color: '$black-alpha-50',
    },
  },
  '& blockquote + blockquote': {
    marginTop: -12,
  },
  '&.answer blockquote': {
    '&:before': {
      background: '$green-300',
    },
    '& h1, &:not(:has(h1)) p': {
      color: '$green-800',
    },
  },
  '& pre': {
    whiteSpace: 'wrap',
    marginBottom: '16px',
  },
  '& code': {
    textStyle: 'body',
    color: '$black-alpha-50',
  },
});

export const QuestionMarkdown = ({ source }: { source: string }) => (
  <MarkdownWrapper className="question">
    <PlainMarkdown source={source} />
  </MarkdownWrapper>
);

export const AnswerMarkdown = ({ source }: { source: string }) => (
  <MarkdownWrapper className="answer">
    <PlainMarkdown source={source} />
  </MarkdownWrapper>
);
