import * as React from 'react';
import * as Dialog from 'naan/primitives/modals/dialog';
import { observer } from 'mobx-react';

import {
  Table,
  TableRow,
  TableLabelCell,
  TableValueCell,
} from 'components/account/account-form/table';
import { Text } from 'naan/primitives/text';
import { LargeGap } from '@naan/primitives/spacer/gaps';
// import { VSpacer } from 'naan/primitives/spacer';
import { formatDate } from 'utils/format-date';
import { Button } from 'naan/primitives/button';
import { HStack, VStack } from 'naan/primitives/stack';
import { Box } from 'naan/primitives/box';
import { AppFactory } from 'app/app-factory';
import { styled } from 'naan/stitches.config';
import { AlertIcon } from '@naan/icons/alert-icon';
// import { colors } from '@naan/tokens/colors';
// import { openUrl } from '@naan/primitives/text/external-link';
import { alertSevereError } from '@app/notification-service';
import { ButtonLabel } from '@naan/primitives/button/button.styles';
import { pagePath, profileHomePath } from 'components/nav/path-helpers';
import { useNavigate } from 'react-router-dom';

import __ from 'core/lib/localization';
import { resolveApiMessage } from '@core/models/user-manager/api-messages';
import { ellipsis } from '@utils/string-utils';
import { elipsis } from '@utils/content-string-utils';
import { LocaleCode } from '@utils/util-types';
import { monthsCount } from '@core/lib/localization-helpers';
import {
  pauseSubscriptionDates,
  resumeSubscriptionDates,
  switchToYearlyData,
} from '@cas-shared/subscription-helpers';
import { formatMoney } from '@cas-shared/format-money';

export const TextButton = styled('button', {
  all: 'unset',
  color: '$colors$teal-500',
  textStyle: 'body',
  textDecoration: 'underline',
  cursor: 'pointer',
  display: 'flex',
  '& .icon': {
    color: '$red-400',
  },
});

// const capitalizeFirstLetter = (str: string) => {
//   if (typeof str === 'string') {
//     return str.charAt(0).toUpperCase() + str.slice(1);
//   }

//   return '';
// };

// const formatCardDate = (date: string) => {
//   if (date) {
//     const [month, year] = date.split(' / ');
//     return `${month.length > 1 ? month : '0' + month}/${year.slice(2)}`;
//   }
//   return '';
// };

const CancelSubscriptionDialog = observer(
  ({
    onOk,
    onDismiss,
    l2,
    fullAccessUntil,
  }: {
    onOk: () => void;
    onDismiss: () => void;
    l2: LocaleCode;
    fullAccessUntil: string;
  }) => {
    // const productName = AppFactory.root.productNameForL2(l2);

    const body = !!fullAccessUntil
      ? __(
          "By canceling your subscription you won't receive any further charges, and your access will end on {{date}}",
          'cancelSubscriptionBodyWithAccess',
          {
            date: formatDate(fullAccessUntil),
          }
        )
      : __(
          "By canceling your subscription you won't receive any further charges.",
          'cancelSubscriptionBodyWithoutAccess'
        );

    return (
      <Dialog.Container onDismiss={onDismiss} open={true}>
        <Dialog.Heading>
          {__('Are you sure you want to cancel?', 'areYouSureYouWantToCancel')}
        </Dialog.Heading>
        <Dialog.Body>{body} </Dialog.Body>
        <Dialog.ButtonsContainer>
          <Dialog.Button
            onClick={onDismiss}
            label={__('Go back', 'goBack')}
            presentation="cancel"
          />
          <Dialog.Button
            onClick={() => {
              onOk();
              setTimeout(() => {
                onDismiss();
              }, 100);
            }}
            label={__('Cancel subscription', 'cancelSubscription')}
            presentation={'teal'}
          />
        </Dialog.ButtonsContainer>
      </Dialog.Container>
    );
  }
);

// DEFERRED until after 10.x release
const PauseDialog = observer(
  ({
    onOk,
    onDismiss,
    l2,
    months,
    accessResumesOn,
    billingResumesOn,
  }: {
    onOk: () => void;
    onDismiss: () => void;
    l2: LocaleCode;
    months: number;
    accessResumesOn: string; // iso date
    billingResumesOn: string; // iso date
  }) => {
    const productName = AppFactory.root.productNameForL2(l2);
    const duration = monthsCount(months);
    const accessResumesDate = formatDate(accessResumesOn);
    const billingResumesDate = formatDate(billingResumesOn);

    return (
      <Dialog.Container onDismiss={onDismiss} open={true}>
        <Dialog.Heading>
          {__('Pause subscription for {{duration}}', 'pauseSubscriptionFor', {
            duration,
          })}
        </Dialog.Heading>
        <Dialog.Body>
          {__(
            'By pausing your subscription, your access to the full {{productName}} catalog will stop today, and resume on {{accessResumesDate}}. Your next billing date will be {{billingResumesDate}}.',
            'pauseSubscriptionDialogBody',
            {
              productName,
              accessResumesDate,
              billingResumesDate,
            }
          )}
        </Dialog.Body>
        <Dialog.ButtonsContainer>
          <Dialog.Button
            onClick={onDismiss}
            label={__('Go back', 'goBack')}
            presentation="cancel"
          />
          <Dialog.Button
            onClick={() => {
              onOk();
              setTimeout(() => {
                onDismiss();
              }, 100);
            }}
            label={__('Confirm pause', 'confirmPause')}
            presentation={'teal'}
          />
        </Dialog.ButtonsContainer>
      </Dialog.Container>
    );
  }
);

// DEFERRED until after 10.x release
const ResumeDialog = observer(
  ({
    onOk,
    onDismiss,
    l2,
    newBillingResumesOn,
  }: {
    onOk: () => void;
    onDismiss: () => void;
    l2: LocaleCode;
    newBillingResumesOn: string; // iso date
  }) => {
    const { root } = AppFactory;
    const productName = root.productNameForL2(l2);
    return (
      <Dialog.Container onDismiss={onDismiss} open={true}>
        <Dialog.Heading>
          {__('Resume subscription', 'resumeSubscription')}
        </Dialog.Heading>
        <Dialog.Body>
          {__(
            'By resuming your subscription now, you will regain full access to {{productName}}. Your next billing date will change to {{date}}.',
            'resumeSubscriptionDialogBody',
            {
              productName,
              date: formatDate(newBillingResumesOn),
            }
          )}{' '}
        </Dialog.Body>
        <Dialog.ButtonsContainer>
          <Dialog.Button
            onClick={onDismiss}
            label={__('Go back', 'goBack')}
            presentation="cancel"
          />
          <Dialog.Button
            onClick={() => {
              onOk();
              setTimeout(() => {
                onDismiss();
              }, 100);
            }}
            label={__('Confirm resume', 'confirmResume')}
            presentation={'teal'}
          />
        </Dialog.ButtonsContainer>
      </Dialog.Container>
    );
  }
);

// DEFERRED until after 10.x release
const SwitchToYearlyDialog = observer(
  ({
    onOk,
    onDismiss,
    formattedPrice,
    formattedDate,
    savedPercent,
  }: {
    onOk: () => void;
    onDismiss: () => void;
    formattedPrice: string;
    formattedDate: string;
    savedPercent: number;
  }) => {
    return (
      <Dialog.Container onDismiss={onDismiss} open={true}>
        <Dialog.Heading>
          {__(
            'Switch to yearly and save {{percent}}%',
            'SwitchToYearlyAndSave',
            { percent: savedPercent }
          )}
        </Dialog.Heading>
        <Dialog.Body>
          {__(
            'By continuing, your next charge will be {{amount}} on {{date}}, and every subsequent year until you cancel.',
            'switchToYearlyDialogBody',
            {
              amount: formattedPrice,
              date: formattedDate,
            }
          )}
        </Dialog.Body>
        <Dialog.ButtonsContainer>
          <Dialog.Button
            onClick={onDismiss}
            label={__('Cancel', 'cancel')}
            presentation="cancel"
          />
          <Dialog.Button
            onClick={() => {
              onOk();
              setTimeout(() => {
                onDismiss();
              }, 100);
            }}
            label={__('Switch to yearly', 'switchToYearly')}
            presentation={'teal'}
          />
        </Dialog.ButtonsContainer>
      </Dialog.Container>
    );
  }
);

export const FullAutoRenew = observer(({ l2 }: { l2: LocaleCode }) => {
  const { userManager, storyManager } = AppFactory.root;
  const membershipData = userManager.membershipDataL2(l2);
  const {
    statusKey,
    membershipTypeDisplay,
    autoRenewAmountDisplay,
    showSwitchPlanInterval,
    fullAccessUntil,
    pausedUntil,
    billingResumesOn,
    autoRenewFailed,
    paymentFailureMessage,
  } = membershipData;

  const entitlementData = userManager.nodeEntitlementL2(l2);
  const effectiveDate = storyManager.currentDate;

  const isSuspended = statusKey === 'suspended';
  const isPaused = statusKey === 'paused';
  const renewsOn = isPaused ? billingResumesOn : fullAccessUntil;

  // const { paymentData } = accountData;
  const navigate = useNavigate();

  const gotoStripePortal = React.useCallback(() => {
    userManager
      .createStripePortalSession(null /*default returnUrl*/)
      .then(result => {
        // console.log(`stripe portal: ${JSON.stringify(result)}`);
        const { url } = result;
        window.location.href = url;
      })
      .catch(error => {
        alertSevereError({ error, note: 'gotoStripePortal' });
      });
  }, [userManager]);

  const handleCancelAutoRenew = () => {
    AppFactory.dialogPresenter.present(onDismiss => (
      <CancelSubscriptionDialog
        onOk={() => {
          onDismiss();
          userManager
            .cancelAutoRenew({ l2 })
            .then(result => {
              navigate(pagePath('exit-survey'));
              const message = resolveApiMessage(result);
              if (message) {
                AppFactory.toastService.open({
                  type: 'success',
                  message,
                });
              }
            })
            .catch(error => {
              const message = resolveApiMessage(error) || error.userMessage;
              if (message) {
                AppFactory.toastService.open({
                  type: 'error',
                  message,
                });
              } else {
                alertSevereError({ error, note: 'cancelAutoRenew' });
              }
            });
          // setTimeout(() => {
          //   DialogService.closeDialog();
          // }, 100);
        }}
        onDismiss={onDismiss}
        l2={l2}
        fullAccessUntil={fullAccessUntil}
      />
    ));
    // }
  };

  // DEFERRED until after 10.x release
  const handlePause = (months: number) => {
    const { pausedUntil: accessResumesOn, billingResumesOn } =
      pauseSubscriptionDates(entitlementData, { months, effectiveDate });

    AppFactory.dialogPresenter.present(onDismiss => (
      <PauseDialog
        onOk={() => {
          onDismiss();
          userManager
            .pauseSubscription({ l2, months })
            .then(result => {
              navigate(profileHomePath()); // todo: figure out responsive issue
              const message = resolveApiMessage(result);
              if (message) {
                AppFactory.toastService.open({
                  type: 'success',
                  message,
                });
              }
            })
            .catch(error => {
              const message = resolveApiMessage(error) || error.userMessage;
              if (message) {
                AppFactory.toastService.open({
                  type: 'error',
                  message,
                });
              } else {
                alertSevereError({ error, note: 'cancelAutoRenew' });
              }
            });
          // setTimeout(() => {
          //   DialogService.closeDialog();
          // }, 100);
        }}
        onDismiss={onDismiss}
        l2={l2}
        months={months}
        accessResumesOn={accessResumesOn}
        billingResumesOn={billingResumesOn}
      />
    ));
  };

  // DEFERRED until after 10.x release
  // eslint-disable-next-line no-unused-vars
  const PauseButton = ({ months }: { months: number }) => {
    const duration = monthsCount(months);

    return (
      <Button
        onClick={() => handlePause(months)}
        label={
          __('Pause for {{duration}}', 'pauseForDuration', { duration }) +
          ellipsis
        }
        size={'small'}
        presentation={'redLight'}
        data-test-id="auto-renew-off"
        css={{
          margin: '-4px 0',
        }}
      />
    );
  };

  // DEFERRED until after 10.x release
  const handleResume = () => {
    const { accessUntil: newBillingResumesOn } = resumeSubscriptionDates(
      entitlementData,
      {
        effectiveDate,
      }
    );

    AppFactory.dialogPresenter.present(onDismiss => (
      <ResumeDialog
        onOk={() => {
          onDismiss();
          userManager
            .resumeSubscription()
            .then(result => {
              navigate(profileHomePath()); // todo: figure out responsive issue
              const message = resolveApiMessage(result);
              if (message) {
                AppFactory.toastService.open({
                  type: 'success',
                  message,
                });
              }
            })
            .catch(error => {
              const message = resolveApiMessage(error) || error.userMessage;
              if (message) {
                AppFactory.toastService.open({
                  type: 'error',
                  message,
                });
              } else {
                alertSevereError({ error, note: 'cancelAutoRenew' });
              }
            });
          // setTimeout(() => {
          //   DialogService.closeDialog();
          // }, 100);
        }}
        onDismiss={onDismiss}
        l2={l2}
        newBillingResumesOn={newBillingResumesOn}
      />
    ));
  };

  // DEFERRED until after 10.x release
  const handleSwitchToYearly = () => {
    const { newPrice, currency, newAccessUntil, savedPercent } =
      switchToYearlyData(entitlementData);
    const formattedPrice = formatMoney(newPrice, currency);
    const formattedDate = formatDate(newAccessUntil);

    AppFactory.dialogPresenter.present(onDismiss => (
      <SwitchToYearlyDialog
        onOk={() => {
          onDismiss();
          userManager
            .switchPlanInterval()
            .then(result => {
              navigate(profileHomePath()); // todo: figure out responsive issue
              // todo: confirm if response typing is correct and if the response includes a message
              const message = resolveApiMessage(result as any);
              if (message) {
                AppFactory.toastService.open({
                  type: 'success',
                  message,
                });
              }
            })
            .catch(error => {
              const message = resolveApiMessage(error) || error.userMessage;
              if (message) {
                AppFactory.toastService.open({
                  type: 'error',
                  message,
                });
              } else {
                alertSevereError({ error, note: 'cancelAutoRenew' });
              }
            });
          // setTimeout(() => {
          //   DialogService.closeDialog();
          // }, 100);
        }}
        onDismiss={onDismiss}
        formattedPrice={formattedPrice}
        formattedDate={formattedDate}
        savedPercent={savedPercent}
      />
    ));
  };

  return (
    <>
      {/* <MediumHeading>{__('My membership', 'myMembership')}</MediumHeading>
          <VSpacer size="4" /> */}
      <Table>
        <tbody>
          <TableRow>
            <TableLabelCell>
              {__('Membership type', 'membershipType')}
            </TableLabelCell>
            <TableValueCell>
              <HStack justify={'space'}>
                <VStack
                  css={{
                    maxWidth: '66%',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                >
                  {membershipTypeDisplay}
                  {/* ({statusKey}) */}
                </VStack>
                {/* DEFERRED (always false for now) */}
                {showSwitchPlanInterval ? (
                  <Button
                    onClick={handleSwitchToYearly}
                    label={'Switch to yearly'}
                    size={'small'}
                    presentation={'grayLight'}
                    data-test-id="switch-plan-interval"
                    css={{
                      margin: '-4px 0',
                    }}
                  />
                ) : null}
              </HStack>
            </TableValueCell>
          </TableRow>

          {/* DEFERRED (always false for now) */}
          {isPaused ? (
            <TableRow>
              <TableLabelCell>
                {__('Paused until', 'pausedUntil')}
              </TableLabelCell>
              <TableValueCell>
                <HStack justify={'space'}>
                  <VStack
                    css={{
                      maxWidth: '66%',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    }}
                  >
                    {formatDate(pausedUntil)}
                  </VStack>
                  <Button
                    onClick={handleResume}
                    label={__('Resume', 'resume')}
                    size={'small'}
                    presentation={'redLight'}
                    data-test-id="auto-renew-off"
                    css={{
                      margin: '-4px 0',
                    }}
                  />
                </HStack>
              </TableValueCell>{' '}
            </TableRow>
          ) : null}

          <TableRow>
            <TableLabelCell>{__('Renews', 'renews')}</TableLabelCell>
            <TableValueCell>
              <HStack justify={'space'}>
                {formatDate(renewsOn)}
                {autoRenewFailed
                  ? ' ' + __('(past due)', 'pastDueParens')
                  : null}
              </HStack>
            </TableValueCell>
          </TableRow>

          <TableRow>
            <TableLabelCell>
              {__('Renewal amount', 'renewalAmount')}
            </TableLabelCell>
            <TableValueCell>
              {autoRenewAmountDisplay}
              {autoRenewFailed ? (
                <>
                  {' '}
                  <Text textStyle="body-bold" color="red-400">
                    {__('Failed', 'failed')}
                  </Text>
                  {paymentFailureMessage ? <>{paymentFailureMessage}</> : null}
                </>
              ) : null}
            </TableValueCell>
          </TableRow>

          {isPaused ? null : (
            <TableRow>
              <TableValueCell>
                <HStack justify={'space'}>
                  <VStack
                    css={{
                      maxWidth: '66%',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    }}
                  ></VStack>
                  <Button
                    onClick={handleCancelAutoRenew}
                    label={
                      __('Cancel subscription', 'cancelSubscription') + elipsis
                    }
                    size={'small'}
                    presentation={'redLight'}
                    data-test-id="auto-renew-off"
                    css={{
                      margin: '-4px 0',
                    }}
                  />
                  {/* DEFERRED - add the additional dialog layer with the pause/cancel options */}
                  {/* <PauseButton months={1} />
                    <PauseButton months={3} /> */}
                </HStack>
              </TableValueCell>
            </TableRow>
          )}

          <TableRow>
            <TableLabelCell>
              {__('Payment method', 'paymentMethod')}
            </TableLabelCell>
            <TableValueCell error={isSuspended}>
              <HStack justify={'space'}>
                <Box
                  css={{
                    maxWidth: '66%',
                    overflow: 'hidden',
                  }}
                >
                  <TextButton onClick={gotoStripePortal}>
                    {isSuspended ? (
                      <div className="icon">
                        <AlertIcon />
                      </div>
                    ) : null}
                    <ButtonLabel>
                      {__('View / update', 'viewSlashUpdate')}
                    </ButtonLabel>
                  </TextButton>

                  {/* the button didn't quite render as desired */}
                  {/* <Button
                      label={__(
                        'View / update',
                        'viewUpdate'                        )}
                      onClick={gotoStripePortal}
                      presentation="tealTransparent"
                      css={{ textDecoration: 'underline' }}
                    /> */}
                </Box>
                {/* <FormError error={serverError} /> */}
              </HStack>
            </TableValueCell>
          </TableRow>
        </tbody>
      </Table>
      <LargeGap />
    </>
  );
});
